@tailwind base;
@tailwind components;
@tailwind utilities;

body .p-tooltip-text {
  font-size: 0.8rem;
}

body .pi {
    font-family: "primeicons" !important;
}

body .p-rating .pi-star-fill {
  color: goldenrod !important;
}

body .p-button-sm, .p-placeholder, .p-inputtext, .ng-star-inserted {
  font-family: inter, ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji" !important;
}

.p-dropdown-item.p-ripple {
  white-space: normal;
}

.p-dropdown-panel .p-dropdown-items .p-dropdown-item {
  border-bottom: 1px solid lightgray !important;
}

body .p-progress-spinner {
  position: fixed;
  z-index: 99999;
  width: 50px;
  height: 50px;
  margin: auto;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 99998;
  background-color: rgba(0, 0, 0, 0.7);
  opacity: 0.9;
}

.home-btn {
  font-family: ui-sans-serif, system-ui, -apple-system, Roboto !important;
  margin: 0.75rem !important;
}

.home-btn-sch {
  background-color: #0275f1 !important;
}

.home-btn-sch:hover {
  background: #0056b3 !important;
  color: #ffffff !important;
  border-color: transparent !important;
}

.home-btn-other {
  background-color: #00a42e !important;
}

.home-btn-other:hover {
  background: #008123 !important;
  color: #ffffff !important;
  border-color: transparent !important;
}

.home-btn-prn-adm {
  background-color: #7c4dff !important;
}

.home-btn-prn-adm:hover {
  background: #6739e6 !important;
  color: #ffffff !important;
  border-color: transparent !important;
}

.home-btn-prn-reg {
  background-color: #f49900 !important;
}

.home-btn-prn-reg:hover {
  background: #ce8401 !important;
  color: #ffffff !important;
  border-color: transparent !important;
}

.contact-email:hover {
  text-decoration: underline;
}

.cursor-pointer:hover {
  cursor: pointer;
}

.terms-checkbox {
  vertical-align: middle;
  position: relative;
  bottom: 1px;
}

/*Extra small devices (phones, 600px and down)*/
@media only screen and (max-width: 600px) {
  body .p-dialog {
    position: fixed !important;
    top: 0 !important;
    left: 0 !important;
  }
}

/*Spinner*/
.lds-ripple {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}

.lds-ripple div {
  position: absolute;
  border: 4px solid #fff;
  opacity: 1;
  border-radius: 50%;
  animation: lds-ripple 1s cubic-bezier(0, 0.2, 0.8, 1) infinite;
}

.lds-ripple div:nth-child(2) {
  animation-delay: -0.5s;
}

@keyframes lds-ripple {
  0% {
    top: 36px;
    left: 36px;
    width: 0;
    height: 0;
    opacity: 0;
  }
  4.9% {
    top: 36px;
    left: 36px;
    width: 0;
    height: 0;
    opacity: 0;
  }
  5% {
    top: 36px;
    left: 36px;
    width: 0;
    height: 0;
    opacity: 1;
  }
  100% {
    top: 0;
    left: 0;
    width: 72px;
    height: 72px;
    opacity: 0;
  }
}

.vertically-center {
  display: flex;
  align-items: center;
}
